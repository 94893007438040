import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Glossary, FAQ } from '~/components/Blocks'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import withLocation from '~/hoc/withLocation'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { Link } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import RichTextRenderer from '~/components/ContentfulRichTextRender'

const windowGlobal = typeof window !== 'undefined' && window

const Page = ({ data }) => {
  const { blocks } = { ...data.contentfulNewPage }

  const [screenSize, getDimension] = useState({
    dynamicWidth: windowGlobal && window.innerWidth,
    dynamicHeight: windowGlobal && window.innerHeight
  })

  const setDimension = () => {
    getDimension({
      dynamicWidth: windowGlobal && window.innerWidth,
      dynamicHeight: windowGlobal && window.innerHeight
    })
  }

  const HeroBanner = ({ order, data }) => {
    const { title, subheading, image, buttonTitle, buttonLink } = { ...data }
    return (
      <div
        className="hero-banner container-fluid overflow-hidden px-0"
        style={{ marginTop: order > 0 ? '0px' : '100px' }}
      >
        <div className="row">
          <div
            className="img-fluid bg-white inner-container"
            style={{ backgroundImage: `url('${image && getSrc(image)}')` }}
          >
            <div className="col-12 col-md-6 ps-6 pt-6 pe-6">
              <h1 className="bannerImageTitle txt-white">{title}</h1>
              <h2 className="bannerImageSubtitle text-uppercase txt-white">{subheading}</h2>
              {buttonTitle && buttonLink && (
                <Link className="bannerImageCTA d-flex align-items-center justify-content-center w-100" to={buttonLink}>
                  {buttonTitle}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const FullWidthImageBlock = data => {
    const { image } = { ...data.data }

    return (
      <div className="mt-3 mb-3 overflow-hidden full-width-image-block">
        {image && <img src={getSrc(image)} className="img-fluid w-100 obj-fit-cover" alt="" />}
      </div>
    )
  }

  const LearnBlock = data => {
    const { title, content, additionalInformation } = { ...data.data }
    let clean = title && title.replace(/\s/g, '_').replace(/[`&?\-@#$%^*!',]/gi, '')

    return (
      <div id={`${clean}`} className="container-fluid overflow-hidden px-0">
        <h2 className="font-opensans-light txt-gray-900 pt-5 px-5 fs-44">{title}</h2>
        <div className="row flex-column flex-lg-row px-5">
          <div className="col-12 col-lg-9 mt-3">
            <p className="font-opensans-regular txt-gray-900 fs-20">
              <RichTextRenderer richText={content} />
            </p>
          </div>
          <div className="col-12 col-lg-3 mt-3 mb-3 d-flex flex-column">
            <div className="font-opensans-bold txt-blue-light fs-18">
              <RichTextRenderer richText={additionalInformation} />
              {/*{additionalInformation && renderRichText(additionalInformation, options)}*/}
            </div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (windowGlobal) {
      window.addEventListener('resize', setDimension)

      return () => {
        window.removeEventListener('resize', setDimension)
      }
    }
  }, [screenSize])

  return (
    <Layout>
      <Metadata title="Learn" />
      {blocks.map((block, index) => {
        if (block.__typename === 'ContentfulImageWithOverlayText') {
          return (
            <>
              <HeroBanner key={index} order={index} data={block} />

              <div className="container d-flex flex-row flex-wrap justify-content-start mt-4">
                {blocks?.map((block, index) => {
                  let clean = block.title && block.title.replace(/\s/g, '_').replace(/[`&?\-@#$%^*!',]/gi, '')
                  return (
                    <AnchorLink
                      key={index}
                      to={`#${clean}`}
                      title={block.title && block.title}
                      className="pe-4 fw-bold fs-6 txt-blue-light"
                    />
                  )
                })}
              </div>
            </>
          )
        }
        if (block.__typename === 'ContentfulLearnBlock') return <LearnBlock key={index} data={block} />
        if (block.__typename === 'ContentfulFullWidthImageBlock')
          return <FullWidthImageBlock key={index} data={block} />
        if (block.__typename === 'ContentfulGlossary') return <Glossary key={index} data={block} />
        if (block.__typename === 'ContentfulFaq') return <FAQ key={index} data={block} />
      })}
      <div className="mb-8" />
    </Layout>
  )
}

export default withLocation(Page)

export const query = graphql`
  query NewPageLearnQuery {
    contentfulNewPage(title: { eq: "Learn" }) {
      title
      blocks {
        ... on ContentfulImageWithOverlayText {
          __typename
          id
          fontColor
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          subheading
          buttonTitle
          buttonLink
        }
        ... on ContentfulFullWidthImageBlock {
          __typename
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        ... on ContentfulLearnBlock {
          __typename
          title
          content {
            raw
          }
          additionalInformation {
            raw
          }
        }
        ... on ContentfulMediaBlock {
          __typename
          id
          title
          showTitle
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          imageAltText
          imagePosition
          showBackground
          backgroundColor
          callToAction {
            title
            slug
          }
          callToActionRight
          content {
            raw
          }
        }
        ... on ContentfulFaq {
          __typename
          id
          title
          topics {
            name
            questions {
              question {
                question
              }
              answer {
                answer
              }
            }
          }
        }
        ... on ContentfulGlossary {
          __typename
          id
          title
          glossaryEntries {
            title
            content {
              content
            }
          }
        }
      }
    }
  }
`
