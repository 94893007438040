import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Plus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="currentColor"
    className="bi bi-plus-square"
    viewBox="0 0 20 20"
  >
    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
  </svg>
)

const Minus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="currentColor"
    className="bi bi-dash-square"
    viewBox="0 0 20 20"
  >
    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
  </svg>
)

export const MediaBlock = data => {
  const { title, content, image, imageAltText, imagePosition, showBackground, callToAction, callToActionRight } = {
    ...data.data
  }
  const gImage = getImage(image)

  const Bold = ({ children }) => <span className="fw-bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <>
            <h2>Embedded Asset</h2>
            <pre>
              <code>{JSON.stringify(node, null, 2)}</code>
            </pre>
          </>
        )
      }
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    }
  }

  const ContainerComp = props => {
    let clean = title && title.replace(/\s/g, '_').replace(/[`&?\-@#$%^*!',]/gi, '')
    return (
      <div id={`${clean}`} className={`${showBackground ? 'container-fluid comGrayBg mt-4' : 'container mt-5'} `}>
        {showBackground ? <div className="container pt-5 pb-5">{props.blks}</div> : <>{props.blks}</>}
      </div>
    )
  }

  const MBlock = () => {
    return (
      <>
        <div className={`row flex-column ${imagePosition ? 'flex-md-row-reverse' : 'flex-md-row'}`}>
          <div className="col order-last order-md-first">
            {gImage && <GatsbyImage image={gImage} class="img-fluid w-100" alt={imageAltText} />}
          </div>
          <div className="col order-first order-md-last">
            <div className="mb-3 mt-sm-3 mt-md-0">{title && <h2>{title}</h2>}</div>
            <div>{content && renderRichText(content, options)}</div>
          </div>
        </div>
        {callToAction && (
          <div className="mt-4">
            <div className="row">
              <div className="col">
                {callToActionRight ? null : (
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <a href={callToAction.slug} type="button" className="btn darkNavyBtn py-2 px-5 text-uppercase">
                      {callToAction.title}
                    </a>
                  </div>
                )}
              </div>
              <div className="col">
                {callToActionRight ? (
                  <div className="d-flex flex-row justify-content-center justify-content-lg-end mt-3">
                    <a href={callToAction.slug} type="button" className="btn darkNavyBtn py-2 px-5 text-uppercase">
                      {callToAction.title}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  return <ContainerComp blks={<MBlock />} />
}

export const ImageWithOverlay = data => {
  const { title, subheading, image, imageAltText } = { ...data.data }
  const gImage = getImage(image)

  return (
    <div className="container-fluid p-0">
      <div className="position-relative">
        <div className="position-absolute top-0 start-0 p-5 imgOverLayText">
          {title && <h1>{title}</h1>}
          {subheading && <p className="mt-2">{subheading}</p>}
        </div>
        {gImage && (
          <div>
            <GatsbyImage
              image={gImage}
              style={{ maxHeight: '50vh', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              alt={imageAltText}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const Glossary = data => {
  const { title, glossaryEntries } = { ...data.data }
  let clean = title && title.replace(/\s/g, '_').replace(/[`&?\-@#$%^*!',]/gi, '')

  return (
    <div id={`${clean}`} className="container-fluid px-5 mt-5">
      <h2 className="font-opensans-light txt-gray-900 fs-44">{title}</h2>
      <div className="row row-cols-1 row-cols-md-2 mt-4">
        {glossaryEntries &&
          glossaryEntries.map((entry, index) => (
            <div key={index} className="col d-flex flex-column">
              <div>
                <span className="font-SFPro-bold txt-gray-900 fs-24">{entry.title}</span>
              </div>
              <div className="mt-0 ">
                <p className="lh-32 font-opensans-regular txt-gray-900 fs-20">
                  {entry.content && entry.content.content}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export const FAQ = data => {
  const { title, topics } = { ...data.data }
  const [openIndex, setOpenIndex] = useState(-111)
  let clean = title && title.replace(/\s/g, '_').replace(/[`&?\-@#$%^*!',]/gi, '')

  const handleChange = qindex => {
    if (qindex === openIndex) {
      setOpenIndex(-111)
    } else {
      setOpenIndex(qindex)
    }
  }

  const onKeyPressHandler = (e, qindex) => {
    if (e.keyCode === 13) {
      handleChange(qindex)
    }
  }

  return (
    <div id={`${clean}`} className="container-fluid px-5 mt-5 mb-3">
      <h2 className="font-opensans-light txt-gray-900 fs-44">{title && title}</h2>
      {topics?.map((topic, index) => (
        <div key={index} className="mt-4">
          <span className="font-SFPro-bold txt-gray-900 fs-24">{topic.name && topic.name}</span>
          {topic.questions && topic.questions.length > 0 && (
            <>
              {topic.questions?.map((q, qindex) => {
                return (
                  <div
                    key={qindex}
                    className="font-opensans-regular txt-gray-900 fs-20
                     c-pointer d-flex flex-row w-75 justify-content-between border-bottom pb-1 mt-2"
                    onClick={() => handleChange(qindex)}
                    onKeyPress={e => onKeyPressHandler(e, qindex)}
                  >
                    <div>
                      <div className="mb-3">{q.question.question && q.question.question}</div>
                      {qindex === openIndex && q.answer && q.answer.answer && <p>{q.answer.answer}</p>}
                    </div>
                    <div role="button" tabIndex="0" className="font-opensans-blod txt-gray-400 fs-24">
                      {qindex === openIndex ? <Minus /> : <Plus />}
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      ))}
    </div>
  )
}
